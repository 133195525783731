import baseUtil from "../js/utils.js"

// 获取盲盒分组
export const getBoxList = (data) => baseUtil.sendRequest({
	url: '/Group/getList',
	...data
})

// 盲盒分组下的抽奖券配置项
export const getLotteryTicketConfig = (data) => baseUtil.sendRequest({
	url: '/Group/getLotteryTicketConfig',
	...data
})

// 用户下单(购买盲盒)
export const buyLotteryTicket = (data) => baseUtil.sendRequest({
	url: '/BuyRaffleTickets/buyLotteryTicket',
	...data
})

// 待开启(的盒子)
export const getWaitOpen = (data) => baseUtil.sendRequest({
	url: '/BoxOpened/waitOpen',
	...data
})

// 开盒子
export const startOpenBox = (data) => baseUtil.sendRequest({
	url: '/BoxOpened/getOpen',
	...data
})

// 重抽盒子
export const resetOpenBox = (data) => baseUtil.sendRequest({
	url: '/BoxOpened/getReset',
	...data
})

// 商品列表
export const getGroupList = (data) => baseUtil.sendRequest({
	url: '/ProductConfig/getGroupList',
	...data
})

// 评论列表
export const getCommengList = (data) => baseUtil.sendRequest({
	url: '/OrderComment/getList',
	...data
})

// 查询订单状态
export const getOrderStatus = (data) => baseUtil.sendRequest({
	url: '/BuyRaffleTickets/getStatus',
	...data
})

// 库存——待签发(的盒子)
export const getWaitConfirmed = (data) => baseUtil.sendRequest({
	url: '/BoxOpened/waitConfirmed',
	...data
})

// 已签发(的盒子历史订单)
export const getHistory = (data) => baseUtil.sendRequest({
	url: '/BoxOpened/history',
	...data
})

// 购买价格换算(券后价计算)
export const getTotalAmount = (data) => baseUtil.sendRequest({
	url: '/BuyRaffleTickets/getTotalAmount',
	...data
})

// 单个盲盒分组信息(含分组券价格)
export const getGroupInfo = (data) => baseUtil.sendRequest({
	url: '/Group/getGroupInfo',
	...data
})

// (女神)盲盒商品列表
export const getProductGroupList = (data) => baseUtil.sendRequest({
	url: '/ProductConfig/getProductGroupList',
	...data
})

// (女神)盲盒商品详情
export const getDetail = (data) => baseUtil.sendRequest({
	url: '/Product/getDetail',
	...data
})

// (开出盲盒)滚动消息
export const getProductList = (data) => baseUtil.sendRequest({
	url: '/SysMsg/getProductList',
	...data
})

// (开出盲盒)滚动消息
export const getMaxProductList = (data) => baseUtil.sendRequest({
	url: '/SysMsg/getMaxProductList',
	...data
})

// 签发盒子
export const signOrder = (data) => baseUtil.sendRequest({
	url: '/LogisticsOrder/signOrder',
	...data
})

// 添加评论
export const addComment = (data) => baseUtil.sendRequest({
	url: '/OrderComment/add',
	...data
})

// (盲盒分组下的)用户(盲盒/重抽)券统计
export const getCouponsCount = (data) => baseUtil.sendRequest({
	url: '/UserCoupons/getCouponsCount',
	...data
})



// banner-消息界面banner(列表信息)
export const getNoticeList = (data) => baseUtil.sendRequest({
	url: '/Banner/getNoticeList',
	...data
})


