<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <van-nav-bar left-arrow  @click-left="onClickLeft" :safe-area-inset-top="true" fixed>
    <template slot="left">
      <van-icon name="arrow-left" />
    </template>
    <template slot="right">
      <slot name="right"></slot>
    </template>
  </van-nav-bar>
</template>

<script>
  export default {
    name: "top-bar-black",
    props: {
      isCustom: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      onClickLeft () {
        if (this.isCustom) {
          this.$emit('onClickLeft')
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
.van-nav-bar{
  background: transparent;
  & ::v-deep .van-nav-bar__content {
    padding: 64px 0 24px;
    height: 40px;
  }
 & ::v-deep .van-nav-bar__left {

   font-size: 50px;
    & .van-icon {

      color: #000;
    }
  }
  &::after {
    border: none;
  }
}

</style>
