<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <div class="girl-index-wrap">
    <top-bar-black v-if="env"/>
    <div class="tickets-line">
      <div class="left"></div>
      <div class="right">
        <div class="right-1" @click="gotoOpen">
          <img src="../../assets/nsmh_icon_bx_big.png" mode="" />
          <div class="text">{{ mhTicketsNum }}</div>
        </div>
        <div class="right-1 right-2">
          <img src="../../assets/quan.png" mode="" />
          <div class="text">{{ ccTicketsNum }}</div>
        </div>
      </div>
    </div>
    <div class="dm">
<!--      <l-barrage ref="lBarrage" :minTop="14" :maxTop="30" :minTime="10" :maxTime="10"></l-barrage>-->
    </div>
    <div class="content">
      <div class="img-wrap">
      </div>
    </div>
    <div class="context">
      <img @click="gotoRank" class="rank-img" src="../../assets/nsmh_btn_form.png" mode="" />
      <div class="bg"></div>
      <div class="line1">
        <div class="goods">
          <div class="goods-bg"></div>
          <img class="product-img" :src="productInfo.product_info ? productInfo.product_info.small_image_url : ''" mode="" />
          <div class="title">{{ productInfo.product_info ? productInfo.product_info.product_market_price_float : '' }}</div>
        </div>
        <div class="name">
          <div class="text1">{{ productInfo.name }}</div>
          <div class="text2 girl-box-shadow">{{ productInfo.des }}</div>
        </div>
        <div class="comment girl-box-btn-shadow" @click="gotoShopList">
          <img src="../../assets/nsmh_btn_sphepl.png" mode="" />
          <div class="text">商品/评论</div>
        </div>
      </div>
      <div class="line2">
        <div class="auto">
          <van-switch class="auto-switch" v-model="usedticket" @change="switchChange" active-color="#EE7F18"/>
          <div class="text">自动用券:{{ usedticket ? '开' : '关' }}</div>
        </div>
        <div class="step">
          <img class="sub" src="../../assets/nsmh_btn_jian.png" mode="" @click="subNum" />
          <div class="num">

            <div class="num1">{{ num }}</div>
            <div class="num2">￥{{ curPrice }}</div>
          </div>
          <img class="add" src="../../assets/nsmh_btn_jia.png" mode="" @click="addNum" />
        </div>
        <div class="times">
          <div class="times5">
            <img src="../../assets/nsmh_btn_5x.png" mode="" @click="numChange(5)" />
          </div>
          <div class="times10">
            <img src="../../assets/nsmh_btn_10x.png" mode="" @click="numChange(10)" />
          </div>
        </div>
      </div>
      <div class="line3">
        <div class="text girl-box-btn-shadow" @click="gotoBuyLottery">
          ￥{{ usedticket ? after_coupon_float : total_amount_float }}
          <image src="../../static/assets/nsmh_text_goumai@2x.png"></image>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import topBarBlack from '../../components/top-bar-black'
  import { buyLotteryTicket, getOrderStatus, getTotalAmount, getGroupInfo, getCouponsCount, getProductList} from '../../common/api/shop'
  export default {
    components: {topBarBlack},
    data() {
      return {
        item: { id: 1 },
        max: 100, // 最大购买数量
        num: 1, // 当前购买数量
        usedticket: true, // 是否用券
        after_coupon_float: '', // 用券后总价
        total_amount_float: '', // 用券前总价
        productInfo: {},
        curPrice: '',
        mhTicketsNum: 0, // 盲盒数量
        ccTicketsNum: 0, // 重抽数量
        env: this.$route.query.env == 'app' ? false : true
      };
    },
    mounted () {
      this.getTotal()
      this.getMain()
      this.initDm()
      this.initTicket()
    },
    activated () {
      this.initDm()
      this.initTicket()
    },
    destroyed () {
      // this.$refs.lBarrage.stop()
    },
    methods: {
      initDm () {
        getProductList({
          data: {
            size: 100
          },
          success: res => {
            if (res.code == 200) {
              let list = res.data.list, dmList = []
              list.forEach(item => {
                dmList.push(item.nickname + '抽中了' + item.product_name)
              })
              // this.$refs.lBarrage.start(dmList)
            }
          }
        })
      },
      // 初始化券
      initTicket () {
        getCouponsCount({
          data: {
            group_id: this.item.id || 1
          },
          success: (res) => {
            if (res.code == 200) {
              this.mhTicketsNum = res.data.lottery_ticket_num
              this.ccTicketsNum = res.data.reset_lottery_ticket_num
            }
          }
        })
      },
      /**
       * @description 跳转订单
       * @param void
       * @author 肖
       * @create_date 2021/6/5
       * @return void
       * @BSD
      */
      gotoRank () {
        this.$router.push({
          name: 'orderList'
        })
      },
      numChange (num) {
        this.num = num
        this.getTotal()
      },
      addNum () {
        if (this.num < this.max) {
          this.num = this.num + 1
          this.getTotal()
        }
      },
      subNum () {
        if (this.num > 1) {
          this.num = this.num - 1
          this.getTotal()
        }
      },
      switchChange (value) {
        this.usedticket = value
        localStorage.setItem('usedticket', this.usedticket)
        this.getTotal()
      },
      // 购买盲盒
      gotoBuyLottery () {
        buyLotteryTicket({
          data: {
            num: this.num,
            type: "1", // 1盲盒券2重抽券
            group_id: this.item.id,
            openid: localStorage.getItem('openid'),
            is_use_coupons: this.usedticket ? "1": "0",
          },
          success: (res) => {
            console.log(res)
            if (res.code == 200) {
              const token = localStorage.getItem('token')
              // window.location.href = res.data.request_info.mweb_url
              window.location.href = res.data.request_info.mweb_url + '&redirect_url=https%3A%2F%2Fh5test.agpt.vip%2F%23%2FboxOpen%3Ftoken%3D' + token
              // console.log({
              //   provider: 'wxpay',
              //   orderInfo: res.data.local_order_info, //微信、支付宝订单数据
              //   timeStamp: new Date().getTime(),
              //   nonceStr: res.data.request_info.nonce_str,
              //   package: '',
              //   signType: 'MD5',
              //   paySign: res.data.request_info.sign
              //
              // })
              // console.log(res.data.local_order_info)

              // uni.requestPayment({
              //   provider: 'wxpay',
              //   orderInfo: res.data.local_order_info, //微信、支付宝订单数据
              //   timeStamp: res.data.request_info.timeStamp,
              //   nonceStr: res.data.request_info.nonceStr,
              //   package: res.data.request_info.package,
              //   signType: 'MD5',
              //   paySign: res.data.request_info.paySign,
              //   success: result => {
              //     getOrderStatus({
              //       data: {
              //         order_no: res.data.local_order_info.order_no,
              //       },
              //       success: () => {
              //         uni.navigateTo({
              //           url: '/pagesGirl/girlBoxOpen/girlBoxOpen?item=' + JSON.stringify(this.item)
              //         })
              //       }
              //     })
              //   },
              //   fail: function (err) {
              //     console.log('fail:' + JSON.stringify(err));
              //   }
              // });
            }
          }
        })
      },
      /**
       * @description 跳转开盒页
       * @param void
       * @author 肖
       * @create_date 2021/6/5
       * @return void
       * @BSD
      */
      gotoOpen () {
        if (this.mhTicketsNum > 0) {
          this.$router.push({
            name: 'boxOpen'
          })
          // uni.navigateTo({
          //   url: '/pagesGirl/girlBoxOpen/girlBoxOpen?item=' + JSON.stringify(this.item)
          // })
        }
      },
      /**
       * @description 跳转商品列表
       * @param void
       * @author 肖
       * @create_date 2021/6/5
       * @return void
       * @BSD
      */
      gotoShopList () {
        this.$router.push({
          name: 'boxGoodsList'
        })
      },
      // 计算券后总价
      getTotal () {
        getTotalAmount({
          data: {
            type: 1,
            group_id: this.item.id,
            num: this.num
          },
          success: res => {
            if (res.code == 200) {
              this.after_coupon_float = res.data.after_coupon_float
              this.total_amount_float = res.data.total_amount_float
            }
          }
        })
      },
      // 获取单个盲盒分组信息
      getMain () {
        getGroupInfo ({
          data: {
            group_id: this.item.id
          },
          success: res => {
            if (res.code == 200) {
              this.productInfo = res.data
              this.curPrice = res.data.list.filter(item => item.type == 1)[0].price_float
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import './boxIndex.scss'
</style>

